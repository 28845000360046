import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { htmlSafe } from '@ember/template';

@classic
@classNames('event')
@classNameBindings('feedItem.isNew:is-new')
export default class FeedEvent extends Component {
  feedItem = null;
  forceOpen = false;

  get subject() {
    return htmlSafe(
      this.feedItem.subject?.replace(
        /\((evt|job):(.*)\)/,
        '<a href="https://logtail.com/team/151495/tail?rf=now-30d&q=$2" target="_blank" data-tooltip="Open in Logtail" data-test-sf-activity-feed-event__log-tail-link-in-subject>$1:$2</a>'
      )
    );
  }

  get dataDogRequestId() {
    // TODO: This does not appear to work correctly
    return this.feedItem.requestId.replace(/^/, '@').replace('=', ':');
  }
}
