import Fixture, {
  element,
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';

export default class SfActivityFeedFeedEventFixture extends Fixture {
  constructor(_, value, scope) {
    super('data-test-sf-activity-feed-event', value, scope);
  }

  @element({ scope: null }) date;
  @element({ scope: null }) content;
  @element({ scope: null }) subject;
  @element({ scope: null }) details;
  @element({ scope: null }) tagline;
  @element({ scope: null })
  secondTagline;
  @element({
    selector: 'data-test-sf-activity-feed-event__details__collapsed-content',
    scope: null
  })
  detailsCollapsedContent;
  @element({ scope: null })
  relationshipChanges;
  @element({
    selector: 'data-test-sf-activity-feed-event__relationship-changes__collapsed-content',
    scope: null
  })
  relationshipChangesCollapsedContent;
  @element({ scope: null }) logTailLink;
  @element({ scope: null })
  logTailLinkInSubject;
}

export const sfFeedEvent = getElementDecoratorFor(SfActivityFeedFeedEventFixture);
export const sfFeedEventWithValue = getElementWithValueDecoratorFor(SfActivityFeedFeedEventFixture);
