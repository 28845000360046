import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import { ceilTo } from 'renard/utils/numbers';
import { inject as service } from '@ember/service';

/*
 * Organize by single line properties,
 * multi-line computed properties,
 * then functions
 * then actions
 */
@classic
@classNames('form-control fde-price-range-control')
export default class PriceRangeControl extends Component {
  @service userSession;

  /** @type {number} defaults.retailPrice */
  @alias('value.retailPrice')
  retailPrice;

  /** @type {string} defaults.restaurantPrice */
  @alias('value.restaurantPrice')
  restaurantPrice;

  /** @type {number} defaults.clientPrice */
  @alias('value.clientPrice')
  clientPrice;

  /** @type {Object} Instance of InvoicingTaxRate that will be set on a menu item */
  @alias('value.taxRate')
  taxRate;

  /**
   * @type {float} defaults.clientPercentage
   * will be calculated at 1.15% of retail price
   */
  clientPercentage = 1.15;

  /**
   * @type {float} defaults.restaurantPercentage
   * will be calculated at 0.85% of retail price
   */
  restaurantPercentage = 0.85;

  /** @type {taxRate[]} defaults.clientPrice */
  areaTaxRates = [];

  /** @type {boolean} Toggles disabled class on retail price input */
  disableRetailPrice = false;

  /** @type {boolean} Toggles disabled class on retail price input */
  disableRestaurantPrice = false;

  /** @type {boolean} Toggles disabled class on retail price input */
  disableClientPrice = false;

  /** @type {boolean} Toggles hide class on retail price input */
  showRetailPrice = true;

  /** @type {boolean} Toggles hide class on retail price input */
  showRestaurantPrice = true;

  /** @type {boolean} Toggles hide class on retail price input */
  showClientPrice = true;

  /** @type {boolean} */
  showTaxRates = true;

  /** @type {number} denominator to ceil the client price with using ceil to */
  clientRangePriceDenominator = 4;

  onChange() {}

  /** @type {string} */
  @computed('showRetailPrice', 'showRestaurantPrice', 'showClientPrice')
  get numberOfFields() {
    return this.get('showClientPrice') && this.get('showRestaurantPrice') ? 'three' : 'two';
  }

  /**
   * Rescues NaN values and rounds to two decimal points
   * @param number
   * @returns {string}
   */
  toFixed(number) {
    return parseFloat(number || '0', 10).toFixed(2);
  }

  /**
   * Notify change handlers of the change
   * @param {Object} taxRate
   * @param {number} retailPrice
   * @param {number} clientPrice
   * @param {number} restaurantPrice
   * @private
   */
  @(task(function* (
    taxRate = null,
    retailPrice = null,
    clientPrice = null,
    restaurantPrice = null
  ) {
    if (taxRate == null) {
      yield timeout(1500);
    }

    yield this.onChange({
      retailPrice: this.toFixed(retailPrice || this.get('retailPrice')),
      restaurantPrice: this.toFixed(restaurantPrice || this.get('restaurantPrice')),
      clientPrice: this.toFixed(clientPrice || this.get('clientPrice')),
      taxRate: taxRate || this.get('taxRate')
    });
  }).restartable())
  _sendChange;

  @action
  calculatePrices(e) {
    const retailPrice = e.target.value;
    const calculatePrice = (key) => this.toFixed(retailPrice * this.get(key));
    const defaultRestaurantPrice = calculatePrice('restaurantPercentage');

    // @TODO: remove this and set 1.05 as default once pricing pilot is no longer necessary.
    if (this.userSession.session.user?.hasFeatureEnabled('newMarkup')) {
      this.set('clientPercentage', 1.05);
    }

    const defaultClientPrice = ceilTo(
      calculatePrice('clientPercentage'),
      this.clientRangePriceDenominator
    );
    this.get('_sendChange').perform(null, retailPrice, defaultClientPrice, defaultRestaurantPrice);
  }

  @action
  updateRestaurantPrice(e) {
    const newRestaurantPrice = e.target.value;
    this.get('_sendChange').perform(null, null, null, newRestaurantPrice);
  }

  @action
  updateClientPrice(e) {
    const newClientPrice = e.target.value;
    this.get('_sendChange').perform(null, null, newClientPrice);
  }

  @action
  updateTaxRate(taxRate) {
    this.get('_sendChange').perform(taxRate);
  }

  @action
  selectField(e) {
    const $field = this.$(e.target);
    $field.select();
  }
}
